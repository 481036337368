.custom-editor .DraftEditor-root {
      min-height: 300px;  /* Adjust the height as needed */
      max-height: 500px;  /* Adjust the height as needed */
      overflow: auto;
    }
    
    .custom-editor {
      border: 1px solid #ddd; /* Optional: Add a border for better visibility */
      padding: 10px;         /* Optional: Add padding for better spacing */
      border-radius: 4px;    /* Optional: Add border radius for better styling */
      box-shadow: rgb(216, 213, 213) 0px 0px 10px; /* Optional: Add shadow for better styling */
    }
    