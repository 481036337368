.infinity-container {
  background-color: #1a1a2e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.infinity-svg {
  width: 600px;
  height: 300px;
}

.infinity-path {
  filter: drop-shadow(0 0 8px #ff4500);
}

.label {
  font-size: 18px;
  fill: white;
}

.card {
  position: relative;
  width: 700px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.hover-shadow {
  position: absolute;
  top: 0;
  left: 120%;
  width: 25%;
  height: 100%;
  background: rgb(192, 208, 255, 0.7);
  transform: skewX(-15deg);
  transition: all 1.1s ease;
  pointer-events: none;
}

.card:hover .hover-shadow {
  left: -120%;
}
