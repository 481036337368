@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[class*="primary"] {
  background-color: #202020 !important;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #20202030 !important;
}

.ant-table-wrapper .ant-table-container {
  border: 1px solid #20202030;
  border-radius: 9px;
  box-shadow: 0 0 10px 0 #20202030;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-bottom-color: #20202016 !important;
}

.custom-editor img {
  max-width: 100%;
  height: auto;
}

.modal-scrollable-content {
  max-height: calc(80vh - 55px); /* Adjust based on your modal header height */
  overflow-y: auto;
  padding-right: 20px; /* Add some padding to account for the scrollbar */
}

/* Optional: Style the scrollbar */
.modal-scrollable-content::-webkit-scrollbar {
  width: 8px;
}

.modal-scrollable-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-scrollable-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
